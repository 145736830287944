import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { t } from 'react-multi-lang'
import { toast } from 'react-toastify'

import Header from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
import Loading from '../../../components/Loading'
import useAuth from '../../../hooks/useAuth'
import api from '../../../services/api'

import {
  ContainerPlan
} from './styled'

const Plan = () => {
  const { user } = useAuth()
  const [plans, setPlans] = useState([])

  async function getPlans () {
    const { data }: any = await api.postMethod('subscription_plans', {})

    setPlans(data.data)
  }

  async function handleToStripe (subscriptionId: string) {
    const userId = localStorage.getItem('userId')
    const { data: subscription }: any = await api.postMethod('get-payment', { userId })
    if (!subscription.data) {
      const { data: info }: any = await axios.get('https://geolocation-db.com/json/')

      const successUrl = `${window.location.origin}/success-payment?userId=${userId}&subscription_id=${subscriptionId}&payment_mode=subscription`
      const cancelUrl = `${window.location.origin}/plans`

      const { data }: any = await api.postMethod('create-checkout-session', {
        subscription_id: subscriptionId,
        successUrl,
        cancelUrl,
        email: user?.email,
        ip: info.IPv4
      })

      window.location.href = data.url
    } else {
      toast.error('This account already has an active plan.')
    }
  }

  useEffect(() => {
    getPlans()
  }, [])

  if (!plans) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <ContainerPlan>
        {plans.filter((plan: any) => plan.price_id.length > 2).map((subscription: any) => (
          <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-5"
            key={subscription.subscription_id}
          >
            <div className="plan-card">
              <div className="plan-head">
                {subscription.title}
              </div>
              <div
                className={
                  'plan-price' +
                  (+subscription.popular_status === 1
                    ? ' premium'
                    : '')
                }
              >
                <p>{t('plan')}</p>
                <h4>
                  {subscription.currency}
                  {subscription.amount} / {t('month')}
                </h4>
              </div>
              <div className="plan-details">
                <h4>{t('maintain_account')}</h4>
                <h5>
                  <i className="fas fa-user-plus" />
                  {subscription.no_of_account}
                </h5>
                <p>{subscription.description}</p>
                <div className="d-flex justify-content-end mt-4">
                  <ButtonOrange onClick={() => handleToStripe(subscription.subscription_id)} className="btn">
                    {t('pay_now')}
                  </ButtonOrange>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ContainerPlan>
    </>
  )
}

export default Plan
