import styled from 'styled-components'
import imageBg from '../../../../assets/images/billing.jpg'

export const ContainerBillingDetails = styled.div`
  color: #FFF;
  margin-top: 6em;
  padding: 0 55px;
  
  .grey-line{
    border-top:1px solid #999;
    margin:.8em 0 1em 0;
  }

  .billing-img{
    height:35em;
    background: url(${imageBg});
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .billing-img-overlay{
    background-color: #00000080;
    height: 35em;
    padding:1em;
    overflow-y:auto;
    overflow-x: hidden;
  }

  .billing-content-sec{
    height: 35em;
    padding:1em;
    background-color: #eee;
    overflow-y:auto;
    overflow-x: hidden;
    color:#333;
  }

  .billing-content-sec .card{
    margin-bottom: 1em;
  }

  .billing-head{
    text-transform: capitalize;
    margin-bottom: 0;
    
  }
  i{
    margin-right: 5px;
  }

`
