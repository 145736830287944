import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { t as translate } from 'react-multi-lang'

import { Button, ContainerLogin, Form, Image, Input } from './styled'

import logoMark from '../../../assets/images/MM-Logomark-2C.png'
import api from '../../../services/api'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [loadingContent, setLoadingContent] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const history = useHistory()

  async function handleSubmit (e: any) {
    e.preventDefault()
    setLoadingContent(translate('button_loading'))
    setButtonDisabled(true)
    const { data }: any = await api.postMethod('forgotpassword', { email })
    if (data?.success) {
      toast.success(data.message)
      history.push('/login')
    } else {
      toast.error(data.error_messages)
    }
    setLoadingContent('')
    setButtonDisabled(false)
  }

  return (
    <ContainerLogin>
      <Link to="/login">
        <Image
          src={logoMark}
          className="site-logo"
          alt="logo_img"
        />
      </Link>
      <div className="container mx-auto">
        <Form className="col-md-6 mx-auto" onSubmit={handleSubmit}>
          <br />
          <div className="form-group">
            <Input
              type="email"
              onChange={(text: React.ChangeEvent<HTMLInputElement>) => setEmail(text.target.value)}
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
            />
          </div>
          <p className="grey-text my-4">
            We will send you an email with instructions on how to reset your password.
          </p>
          <Button
            className="btn p-3"
            disabled={buttonDisabled}
          >
            {
              loadingContent === null
                ? loadingContent
                : translate('Submit')
            }
          </Button>
        </Form>
      </div>
    </ContainerLogin>

  )
}

export default ForgotPassword
