import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { t as translate } from 'react-multi-lang'

import useAuth from '../../../hooks/useAuth'
import { Button, ContainerLogin, Form, Image, Input } from './styled'

import logoMark from '../../../assets/images/MM-Logomark-2C.png'

const Login = (props: any) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loadingContent, setLoadingContent] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const { signIn } = useAuth()

  useEffect(() => {
    const paramsInObject: any = { email: null, password: null }
    props.location.search.substr(1).split('&').forEach((param: any) => (paramsInObject[param.split('=')[0]] = param.split('=')[1]))
    if (paramsInObject.email) {
      setEmail(paramsInObject.email)
      setPassword(paramsInObject.password)
    }
  }, [])

  function handleSubmit (e: any) {
    e.preventDefault()
    setLoadingContent(translate('button_loading'))
    setButtonDisabled(true)
    signIn(email, password)
    setLoadingContent('')
    setButtonDisabled(false)
  }

  return (
    <ContainerLogin>
      <Link to="/login">
        <Image
          src={logoMark}
          className="site-logo"
          alt="logo_img"
        />
      </Link>
      <div className="container mx-auto">
        <Form className="col-md-6 mx-auto" onSubmit={handleSubmit}>
          <br />
          <div className="form-group">
            <Input
              type="email"
              onChange={(text: React.ChangeEvent<HTMLInputElement>) => setEmail(text.target.value)}
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
            />
          </div>
          <div className="form-group mt-2">
            <Input
              type="password"
              onChange={(text: React.ChangeEvent<HTMLInputElement>) => setPassword(text.target.value)}
              className="form-control"
              id="pwd"
              name="password"
              placeholder="Password"
              value={password}
            />
          </div>
          <Link to={'/forgot-password'} className="forgot-password mt-4 mb-2">
            {translate('forgot_password')}
          </Link>
          <Button
            id="lambda-test-btn-login"
            className="btn p-3"
            disabled={buttonDisabled}
          >
            {
              loadingContent === null
                ? loadingContent
                : translate('signin')
            }
          </Button>
          <span>
            {translate('new_to_website')}{' '}
            <Link to={'/register'} className="signup-link">
              {translate('sign_up_now')}
            </Link>
          </span>
        </Form>
      </div>
      {/* <footer className="mt-4">
        <span className="footer-copywriter-brand"><a href="https://www.comuniverse.com.br/" className="link-poweredby">{translate('poweredBy')}</a><sup>&copy;</sup></span>
      </footer> */}
    </ContainerLogin>

  )
}

export default Login
