import React from 'react'
import { Link } from 'react-router-dom'
import packageJson from '../../../../package.json'
import Logo from '../../../components/Logo'

function Version () {
  return (
    <>
      <div className="container d-flex flex-column align-items-center mt-5">
        <Link to="/login">
          <Logo />
        </Link>
        <br />
        <h3>Current version {packageJson.version}</h3>
        <p className="mt-2 font-weight-bold mb-1">Contact administrator for more details</p>
        <a href="mailto:website@manufacturing-masters.com" className="font-weight-bold">
          website@manufacturing-masters.com
        </a>
      </div>
    </>
  )
}

export default Version
