import styled from 'styled-components'

export const ContainerCastCrew = styled.div`
  color: #FFF;
  margin-top: 6em;
  padding: 0 55px;
`

export const ContainerExpert = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 4px;
`

export const Picture = styled.img`
  width: 150px;
  border-radius: 4px;
  margin-right: 16px;
`

export const Info = styled.div`
  min-width: 200px;
  strong {
    width: 200px;
    color: var(--orange-primary);
  }
`
