import styled from 'styled-components'

export const ContainerVideo = styled.div``

export const BackPage = styled.div`
  position: absolute;
  top:4vh;
  left: 4vh;
  color: #FFF;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    margin-right: 8px;
    height: 20px;
    width: 20px;
    transition: .2s linear;
  }

  :hover img{ 
    transform: scale(1.2) translateX(-10px);
  }
`
