import React, { useEffect, useState } from 'react'
import { t } from 'react-multi-lang'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import Header from '../../../../components/Auth/Header'
import { ButtonOrange } from '../../../../components/Buttons'
import Loading from '../../../../components/Loading'
import api from '../../../../services/api'

import { ContainerBillingDetails } from './styled'

const BillingDetails = () => {
  const [billingDetails, setBillingDetails] = useState<any>([])
  // const [cancelStatus, setCancelStatus] = useState<number>(0)
  const [cancelReason, setCancelReason] = useState('')
  const history = useHistory()

  async function getBillingDetails () {
    const { data }: any = await api.postMethod('subscribedPlans', {})

    if (!data.success) {
      history.push('/plans')
      return
    }

    setBillingDetails(data.data)
    // setCancelStatus(billingDetails[0] ? billingDetails[0].cancelled_status : 0)
  }

  // async function handleEnableAutoRenewal () {
  //   const { data }: any = await api.postMethod('autorenewal/enable', {
  //     cancel_reason: 0
  //   })

  //   if (data.success) {
  //     toast.success(data.message)
  //     setCancelStatus(0)
  //   } else {
  //     toast.error(data.error_messages)
  //   }
  // };

  async function handleCancelAutoRenewal () {
    const { data }: any = await api.postMethod('cancel/subscription', { cancel_reason: cancelReason })

    if (data.success) {
      toast.success(data.message)
      // setCancelStatus(1)
    } else {
      toast.error(data.error_messages)
    }
  };

  useEffect(() => {
    getBillingDetails()
  }, [])

  if (!billingDetails) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <ContainerBillingDetails>
        <div className="row pb-3 px-2 mt-5">
          <div className="col-sm-10 col-md-11 col-lg-9 col-xl-8 mx-auto">
            <div className="row m-0">
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0">
                {billingDetails.length > 0
                  ? (
                    <div className="billing-img">
                      <div className="billing-img-overlay">
                        <h4 className="billing-head">
                          <i className="far fa-calendar-alt mr-3" />
                          {t('billing_details')}
                        </h4>

                        <p className="grey-line" />
                        <div className="d-flex">
                          <h5 className="billing-head">
                            <i className="far fa-hand-point-right" />
                          </h5>
                          <div className="content-right">
                            <h5 className="billing-head mb-3">
                              {t('next_billing_date')}
                            </h5>
                            <p className="m-0">
                              {billingDetails[0].expiry_date}
                            </p>
                          </div>
                        </div>

                        <p className="grey-line" />
                        <div className="d-flex">
                          <h5 className="billing-head">
                            <i className="far fa-hand-point-right" />
                          </h5>
                          <div className="content-right">
                            <h5 className="billing-head mb-3">
                              {billingDetails[0].title}
                            </h5>
                            <p className="m-0">
                              {billingDetails[0].currency}
                              {billingDetails[0].amount} /{' '}
                              {billingDetails[0].plan} Months
                            </p>
                          </div>
                        </div>

                        <p className="grey-line" />
                        <div className="d-flex justify-content-end ">
                          <Link to={'/plans'} className="capitalize text-white">
                            <i className="fas fa-chevron-right" />
                            {t('view_plan_details')}
                          </Link>
                        </div>

                        {/* <div className="text-center mt-3">
                          {+cancelStatus === 1
                            ? (
                              <div
                                onClick={handleEnableAutoRenewal}
                                className="btn btn-success"
                              >
                                {t('enable_auto_renewal')}
                              </div>
                              )
                            : (
                              <ButtonOrange
                                as="button"
                                data-toggle="modal"
                                data-target="#cancel-subs"
                              >
                                {t('cancel_subscription')}
                              </ButtonOrange>
                              )}
                        </div> */}
                      </div>
                    </div>
                    )
                  : (
                    <>{t('no_data_found')}</>
                    )}
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 p-0">
                <div className="billing-content-sec">
                  <h4 className="billing-head">
                    <i className="far fa-calendar-alt" />
                    {t('billing_details')}
                  </h4>
                  <p className="grey-line" />
                  {billingDetails.map((subscription: any) => (
                    <div
                      className="card"
                      key={subscription.user_subscription_id}
                    >
                      <div className="card-header bg-dark text-white">
                        {subscription.title}
                      </div>
                      <div className="card-body">
                        <table className="table table-bordered m-0">
                          <tbody>
                            <tr>
                              <td>{t('subscribed_date')}</td>
                              <td>{subscription.created_at}</td>
                            </tr>
                            <tr>
                              <td>{t('expiry_date')}</td>
                              <td>{subscription.expiry_date}</td>
                            </tr>
                            <tr>
                              <td>{t('no_of_account')}</td>
                              <td>{subscription.no_of_account}</td>
                            </tr>
                            <tr>
                              <td>{t('no_of_month')}</td>
                              <td>{subscription.plan}</td>
                            </tr>
                            <tr>
                              <td>{t('total_amount')}</td>
                              <td>
                                {subscription.currency}
                                {subscription.total_amount}
                              </td>
                            </tr>
                            {subscription.wallet_amount > 0
                              ? (
                                <tr>
                                  <td>{t('referral_amount')}</td>
                                  <td>
                                    {subscription.currency}
                                    {subscription.wallet_amount}
                                  </td>
                                </tr>
                                )
                              : (
                                  ''
                                )}

                            {subscription.coupon_code === ''
                              ? (
                                  ''
                                )
                              : (
                                <tr>
                                  <td>{t('coupon_amount')}</td>
                                  <td>
                                    {subscription.currency}
                                    {subscription.coupon_amount}
                                  </td>
                                </tr>
                                )}
                            <tr>
                              <td>{t('paid_amount')}</td>
                              <td>
                                {subscription.currency}
                                {subscription.amount}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('payment_mode')}</td>
                              <td>{subscription.payment_mode}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade confirmation-popup" id="cancel-subs">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form
                className="auth-form cancel-form"
                onSubmit={handleCancelAutoRenewal}
              >
                <div className="modal-header">
                  <h4 className="modal-title">Pause autorenewal</h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="name">cancel reason</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="cancel_reason"
                      value={cancelReason}
                      onChange={text => setCancelReason(text.target.value)}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-link"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <ButtonOrange
                    onClick={handleCancelAutoRenewal}
                  >
                    Submit
                  </ButtonOrange>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ContainerBillingDetails>
    </>
  )
}

export default BillingDetails
