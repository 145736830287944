import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import api from '../../../services/api'

import './styles.css'

const Banner = () => {
  const BANNER_INTERVAL = 30000 // 30s

  const [currentImage, setCurrentImage] = useState<any>({})

  function getRandom (min: number, max: number) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min)
  }

  useEffect(() => {
    api.postMethod('v4/get_banners', {}).then((res: any) => {
      const { data } = res
      if (data.length) {
        setCurrentImage(data[getRandom(0, data.length)])
        setInterval(() => {
          setCurrentImage(data[getRandom(0, data.length)])
        }, BANNER_INTERVAL)
      }
    })
  }, [])

  return <LazyLoadImage
    alt="banner"
    effect="blur"
    src={currentImage.url}
    className="banner-logged d-none d-md-block"
  />
}

export default Banner
