import styled from 'styled-components'

export const ContainerStaticHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  top: 0;
  padding: 2em 1em;
  position: fixed;
  background: var(--navy);
`
