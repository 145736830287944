import styled from 'styled-components'

export const ContainerFooter = styled.div`
  width: 100%;
  padding: 2em;
  color: #999; 

  footer { 
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;

    .footer-top a{
      font-size: 1.14em;
      margin-bottom:1.375em;
      color: #999; 
      text-transform: capitalize;
    }  
  }

`

export const FooterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  width: 100%;

  li {
    margin-bottom:1.375em;

    a {
      font-size: 1.14em;
      color: #999; 

      :hover, 
      :active, 
      :focus {
        text-decoration: underline;
      }

    }
  }
`
export const ImageDownloaded = styled.img`
  height: 35px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position:center;
  margin-bottom: 1em;
`
