import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://manufactureflix-videos.s3.amazonaws.com/Images/Background-Sign-Up-Page.png');
  height: 80vh;
  align-items: center;

  @media only screen and (max-width: 768px) {
    background-image: none;
    width: 100vw;
  }
`

export const MainContainer = styled.div`
  display: flex;
  margin: 30px;

  @media only screen and (max-width: 912px) {
    flex-direction: column-reverse;
  }

  @media only screen and (min-width: 912px) {
    width: 90vw;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33vw;
  justify-content: space-between;
  height: 75vh;
  align-items: center;
  padding: 20px 0 20px;

  @media only screen and (max-width: 912px) {
    width: 100%;
    height: 40vh;
    margin-bottom: 2vh;
    margin-top: 1vh;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 75vh;
  width: 25vw;
  justify-content: center;
  align-items: center;
  border-radius: 3px;

  @media only screen and (max-width: 912px) {
    width: 100%;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 300px) {
    display: inline-flex;
    margin-top: 20vh;
  }

  @media only screen and (max-width: 420px) {
    display: inline-flex;
  }

  @media only screen and (min-width: 3600px) {
    display: inline-flex;
    margin-top: 5vh;
    font-size: 40px;
  }
`

export const ImgLogo = styled.img`
  height: 7vh;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 65vh;
  width: 100%;
`

export const InputDiv = styled.div`
  width: 24vw;
  display:flex;
  justify-content:space-between;

  @media only screen and (max-width: 912px) {
    width: 90%;
  }
`

export const InputStyle = styled.input`
  width: 100%;
  border-radius: 4px;
  border: none;
  background-color: gray;
  height: 40px;

  :hover {
    background-color: #fff;
    border: 2px solid gray;

    ::placeholder {
      color: gray;
    }
  }

  ::placeholder {
  color: #fff;
  padding-left: 10px;
  }

  :-ms-input-placeholder { 
    color: #fff;
  }

  @media only screen and (max-width: 912px) {
    width: 100%;
  }
`

export const CheckboxDiv = styled.div`
  display: flex;
  align-items: center;
  
`

export const CheckboxText = styled.div`
  color: black;
  opacity: none;
  padding-left: 3px;
  display: flex;
  width: 95%;

  :Link {
    color: orange !important;
  }
`

export const SignButton = styled.button`
  width: 90%;
  height: 60px;
  border: 3px solid #dc6701;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: none;
  font-size: 24px;
  font-weight: 700;

  :hover {
    background-color: #dc6701;
    color: #fff;
  }
`

export const OrangeText = styled.div`
  color: #dc6701;
  padding-left: 5px;
`

export const InfoItems = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InfoNumber = styled.div`
  font-size: 56px;
`

export const InfoText = styled.div`
  width: 80%;
  text-align: left;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;

  @media only screen and (max-width: 420px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 3600px) {
    font-size: 40px;
  }
`
