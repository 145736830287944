/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
require('@silvermine/videojs-quality-selector')(videojs)
require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css')

interface IVideoPlayerProps {
  main_resolutions: any[],
  poster: string | undefined,
  main_video: string | undefined,
  full: boolean,
  handleTimer?: (player:any) => void
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  autoplay: true,
  fluid: true,
  playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
  controlBar: {
    children: [
      'playToggle',
      'progressControl',
      'remainingTimeDisplay',
      'timeDivider',
      'volumePanel',
      'qualitySelector',
      'playbackRateMenuButton',
      'fullscreenToggle'
    ]
  }
}

function verifyInternet () {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (navigator.connection) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { downlink }: any = navigator.connection
    if (downlink) {
      if (downlink >= 8.3) {
        return 1080
      } else if (downlink >= 6) {
        return 720
      } else if (downlink >= 3.5) {
        return 480
      } else {
        return 360
      }
    }
  }

  return 480
}

const VideoPlayer = ({ main_resolutions, main_video, poster, full, handleTimer }: IVideoPlayerProps) => {
  const videoNode = useRef<any>()
  const player = useRef<videojs.Player>()

  useEffect(() => {
    player.current = videojs(videoNode.current as HTMLVideoElement, {
      ...initialOptions,
      poster
    })
    const downlink: number = verifyInternet()

    if (Object.keys(main_resolutions).length > 1) {
      const videos: any[] = []

      Object.keys(main_resolutions).forEach((el: any) => {
        if (el !== 'original') {
          const resolution = el.split('x')[1]

          videos.push({
            label: `${resolution}P`,
            src: main_resolutions[el],
            type: 'video/mp4',
            selected: downlink === +resolution
          })
        }
      })
      player.current.src(videos)
    } else {
      player.current.src(main_video as string)
    }
    player.current.play()

    return () => {
      if (player.current) {
        player.current.dispose()
      }
    }
  }, [main_resolutions])

  if (handleTimer) {
    useEffect(() => {
      if (player.current !== undefined) {
        player.current.on('play', () => {
          handleTimer(player)
        })
      }
      return () => {
        player.current = undefined
      }
    }, [])
  }
  return <video id='lambda-test-video' ref={videoNode as any} playsInline className="video-js" style={{ height: full ? '100vh' : '550px' }}/>
}

export default VideoPlayer
