import React, { useEffect } from 'react'

import { AuthProvider } from './contexts/auth'
import { setLanguage, setTranslations } from 'react-multi-lang'
import { ToastContainer } from 'react-toastify'

import Routes from './routes'
import ReactGA from 'react-ga'

import en from './assets/translation/en.json'
import 'react-toastify/dist/ReactToastify.css'

setTranslations({ en })

ReactGA.initialize('G-FHPNEK8D8D')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  useEffect(() => {
    localStorage.setItem('lang', 'en')
    setLanguage('en')
  }, [])

  return (
    <AuthProvider>
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthProvider>
  )
}

export default App
