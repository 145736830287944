import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// import LandPage from '../pages/Common/Landpage'
import PageCustom from '../pages/Common/PageCustom'
import Login from '../pages/Common/Login'
import Register from '../pages/Common/Register'
import ForgotPassword from '../pages/Common/ForgotPassword'
import SuccessContract from '../pages/Auth/Plan/SuccessContract'
import Version from '../pages/Common/Version'
import VideoShare from '../pages/Common/Video'

const Routes = () => (
  <Router>
    <Switch>
      {/* <Route path="/" exact component={LandPage} /> */}
      <Route path="/page/:id" component={PageCustom} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/success-payment" component={SuccessContract} />
      <Route path="/version" component={Version} />
      <Route path="/watch/:unique_id/:token" component={VideoShare} />
      <Route path="**" component={() => <Redirect to="/login" />} />
    </Switch>
  </Router>
)

export default Routes
