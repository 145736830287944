import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { t } from 'react-multi-lang'

function VideoTrailer ({ trailer }: any) {
  const trailerDetails = trailer

  const trailerSlider = {
    dots: false,
    arrow: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false
  }

  return (
        <div className="slider-topbottom-spacing pl-0 pr-0 slider-overlay">
            <div className="pr-4per pl-4per">
                <h1 className="banner_video_title">
                    {t('trailer_and_more')}
                </h1>
            </div>
            <div>
                <Slider
                    {...trailerSlider}
                    className="more-like-slider slider"
                >
                    {trailerDetails.map((trailer: any) => (
                        <div key={Math.random()}>
                            <div className="relative">
                                <img
                                    className="trailers-img placeholder"
                                    alt="episode-img"
                                    src={trailer.default_image}
                                    data-src="assets/img/thumb8.jpg"
                                    srcSet={
                                        trailer.default_image +
                                        ' 1x,' +
                                        trailer.default_image +
                                        ' 1.5x,' +
                                        trailer.default_image +
                                        ' 2x'
                                    }
                                />
                                <div className="trailers-img-overlay">
                                    <Link to={{
                                      pathname: '/trailer',
                                      state: { trailer }
                                    }}>
                                        <div className="thumbslider-outline">
                                            <i className="fas fa-play" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="episode-content">
                                <h4 className="episode-content-head">
                                    {trailer.name}
                                </h4>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
  )
}

export default VideoTrailer
