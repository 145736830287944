import React from 'react'
import { t } from 'react-multi-lang'
import { Link } from 'react-router-dom'

function VideoDetails ({ videoDetailsFirst }: any) {
  return (
        <div className="slider-topbottom-spacing slider-overlay">
            <h1 className="banner_video_title">
                {videoDetailsFirst.title}
            </h1>
            <div className="row">

                <div className="col-lg-2 col-xl-2">
                    <h4 className="detail-head">{t('experts')}</h4>
                    <ul className="detail-list">
                        {videoDetailsFirst.cast_crews.map((cast: any) => (
                            <li key={cast.cast_crew_id}>
                                <Link
                                    to={{
                                      pathname: '/view-all',
                                      state: {
                                        cast_crew_id:
                                                cast.cast_crew_id,
                                        title: cast.name,
                                        videoType: 'cast'
                                      }
                                    }}
                                >
                                    {cast.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="col-lg-8 col-xl-8">
                    <h4 className="detail-head">{t('description')}</h4>
                    <p className="details-text">
                        {videoDetailsFirst.description}
                    </p>
                </div>
            </div>
        </div>
  )
}

export default VideoDetails
