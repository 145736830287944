import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UserHeader from '../../../components/Auth/Header'
import Item from '../../../components/Auth/SliderView/MainSlider/Item'
import Slider from '../../../components/Auth/SliderView/MainSlider/Slider'
import Loading from '../../../components/Loading'
import api from '../../../services/api'

const Category = (props: any) => {
  const [movies, setMovies] = useState<any>([])

  async function searchMovieById (categoryId: any) {
    const { data }: any = await api.postMethod('home_second_section', {
      page_type: 'CATEGORY',
      category_id: categoryId
    })

    if (!data.success) {
      return
    }

    const categoriesSection = data.data.map((movie: any) => {
      const allMovies = process.env.REACT_APP_ENV === 'PROD' ? movie.data.filter((i: any) => i.in_production) : movie.data.filter((i: any) => i.in_test)
      return {
        ...movie,
        data: allMovies
      }
    }).filter((item: any) => item.data.length > 0)

    setMovies(categoriesSection)
  }

  function renderVideoList (main: any, index: number) {
    return (
      <React.Fragment key={index}>
        <div className="main-slidersec">
          <Link
            to={`/sub-category/${main.url_page_id}/${main.title}`}
          >
            <h3 className="heading-3">
              {main.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>

          <Slider>
              {main.data.map((movie: any, index: any) => <Item movie={movie} key={movie.admin_video_id} index={index} />)}
          </Slider>
        </div>
      </React.Fragment>
    )
  };

  useEffect(() => {
    searchMovieById(props.match.params.id)
  }, [props.match.params.id])

  if (!movies) {
    return <Loading />
  }

  return (
    <>
      <UserHeader />
      <div className="mt-5" />
      {
        movies.length > 0
          ? movies.map((main: any, index: any) => renderVideoList(main, index))
          : <div className="main-slidersec"><h3>No video for this category</h3></div>
      }
    </>
  )
}

export default Category
