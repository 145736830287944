/* eslint-disable react/prop-types */
import React from 'react'
import './SliderWrapper.scss'

const SliderWrapper = ({ children }) => (
  <div className="slider-wrapper">
    {children}
  </div>
)

export default SliderWrapper
