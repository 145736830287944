import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Loading from '../../../components/Loading'
import useAuth from '../../../hooks/useAuth'

const Logout = () => {
  const history = useHistory()
  const { logOut } = useAuth()

  useEffect(() => {
    logOut()
    history.push('/login')
  }, [])

  return <Loading />
}

export default Logout
