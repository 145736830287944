/* eslint-disable camelcase */
import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { t } from 'react-multi-lang'
// import { useHistory } from 'react-router'
import { toast, ToastContent } from 'react-toastify'
import api from '../services/api'

type AuthProviderProps = {
  children: ReactNode
}

type AuthContextData = {
  signed: boolean,
  user: User | null,
  userDetails: any,
  signIn: (email: string, password: string) => void
  logOut: () => void,
  me: () => void,
  loading: boolean
}

type User = {
  email: string,
  gender: string,
  is_super_user: number,
  name: string,
  picture: string,
  sub_profile_id: number,
  sub_profile_name: string,
  sub_profile_picture: string,
  token: string,
  token_expiry: string,
  user_id: number,
  user_type: number,
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider ({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null)
  const [userDetails, setUserDetails] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  // const history = useHistory()

  async function me () {
    const user: any = await api.postMethod('profile', {})
    const superUser = localStorage.getItem('isSuperUser')
    setUserDetails({ ...user.data.data, is_super_user: superUser })
  }

  async function signIn (email: string, password: string) {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const { data }: any = await api.postMethod('v4/login', { email, password, timezone })
      if (data.success) {
        localStorage.setItem('userId', data.data.user_id)
        localStorage.setItem('accessToken', data.data.token)
        localStorage.setItem('userType', data.data.user_type)
        localStorage.setItem('push_status', data.data.push_status)
        localStorage.setItem('name', data.data.name)
        localStorage.setItem('isSuperUser', data.data.is_super_user)
        localStorage.setItem('active_profile_id', data.data.sub_profile_id)
        localStorage.setItem('active_profile_image', data.data.picture)
        localStorage.setItem('active_profile_name', data.data.name)
        localStorage.setItem('user', JSON.stringify(data.data))
        localStorage.setItem('first_login', '1')
        setUser(data.data)
        me()
        // history.push('/home')
        toast.success('Log-in successful!')
      } else toast.error(data.error_messages)
    } catch (error) {
      toast.error(error as ToastContent)
    }
  }

  function logOut () {
    setUser(null)
    setUserDetails(null)
    localStorage.clear()
    toast.success(t('logout_success'))
  }

  useEffect(() => {
    setLoading(true)
    const storageUser = localStorage.getItem('user')
    const storageToken = localStorage.getItem('accessToken')

    if (storageUser && storageToken) {
      setUser(JSON.parse(storageUser))
      setLoading(false)
    }
    me()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        signed: !!localStorage.getItem('user'),
        user,
        userDetails,
        me,
        signIn,
        logOut,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
