import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import UserHeader from '../../../components/Auth/Header'
import { ButtonOrange } from '../../../components/Buttons'
import useAuth from '../../../hooks/useAuth'

import api from '../../../services/api'

function AddTeamMember () {
  const [email1, setEmail1] = useState<string>('')
  const [email2, setEmail2] = useState<string>('')
  const [email3, setEmail3] = useState<string>('')
  const [email4, setEmail4] = useState<string>('')

  const history = useHistory()
  const { user } = useAuth()

  async function handleToRegisterMember () {
    if (email1.length > 0 || email2.length > 0 || email3.length > 0 || email4.length > 0) {
      const emails = [email1, email2, email3, email4].filter(email => email !== '')
      const { data }: any = await api.postMethod('v4/register_members', { emails: Object.values(emails), super_user_id: user?.user_id })
      if (data.success) {
        toast.success(data.message)
      }
    }
    history.push('/home')
  }

  return (
    <>
      <UserHeader />
      <br /><br />
      <div className="container mt-5">
        <h1>Add team members</h1>
        <p>You can always add, delete or change team members from your user menu in top right of the screen</p>

        <span>Your membership includes access for up to 4 additional team members</span>
        <form>
          <div className="form-group col-md-5 p-0 mt-3">
            <input
              type="email"
              onChange={text => setEmail1(text.target.value)}
              className="form-control"
              id="email1"
              name="email1"
              placeholder="Team member email"
              value={email1}
            />
          </div>
          <div className="form-group col-md-5 p-0">
            <input
              type="email"
              onChange={text => setEmail2(text.target.value)}
              className="form-control"
              id="email2"
              name="email2"
              placeholder="Team member email"
              value={email2}
            />
          </div>
          <div className="form-group col-md-5 p-0">
            <input
              type="email"
              onChange={text => setEmail3(text.target.value)}
              className="form-control"
              id="email3"
              name="email3"
              placeholder="Team member email"
              value={email3}
            />
          </div>
          <div className="form-group col-md-5 p-0">
            <input
              type="email"
              onChange={text => setEmail4(text.target.value)}
              className="form-control"
              id="email4"
              name="email4"
              placeholder="Team member email"
              value={email4}
            />
          </div>
          <ButtonOrange onClick={handleToRegisterMember}>Save Team & Return to main page</ButtonOrange>
        </form>
      </div>
    </>
  )
}

export default AddTeamMember
